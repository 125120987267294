<template>
    <v-ghost />
</template>

<script>

export default {
    data() {
        return {
        };
    },
};
</script>
